import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import gmailIcon from '../../Assets/gmail_icon.png';
import { useTranslation } from '../../LanguageContext.js';
import Particle from '../Particle';

function Contact() {
  const { t } = useTranslation();

  const handleEmailClick = () => {
    window.location.href = 'mailto:hubert.przypis.it@gmail.com';
  };

  const handleEmailCopy = (emailAddress) => {
    console.log("Copy function triggered for:", emailAddress); // Debugowanie

    // Alternatywna metoda kopiowania
    const tempInput = document.createElement("input");
    tempInput.value = emailAddress;
    document.body.appendChild(tempInput);
    tempInput.select();
    try {
      document.execCommand("copy");
      alert(`Copied email address: ${emailAddress}`);
    } catch (err) {
      console.error('Failed to copy: ', err);
      alert("Failed to copy. Please try again or copy manually.");
    } finally {
      document.body.removeChild(tempInput);
    }
  };

  return (
    <Container fluid className="contact-section">
      <Particle />
      <Container>
        <Row>
          <Col md={6}>
            <h2 className="section-title">{t('contactTitle')}</h2>
            <p>{t('contactDescription')}</p>

            <a
              href="#"
              rel="noopener noreferrer"
              className="gmail-link"
              onClick={handleEmailClick}
            >
              <img src={gmailIcon} alt="Gmail Icon" className="gmail-icon" />
            </a>
          </Col>
          <Col md={6} className="contact-info">
            <h2 className="section-title">{t('contactInfoTitle')}</h2>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => handleEmailCopy('hubert.przypis.it@gmail.com')}
            >
              {t('contactEmailMain')}: hubert.przypis.it@gmail.com
            </p>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => handleEmailCopy('kontakt@hubertprzypis.pl')}
            >
              {t('contactEmail2')}: kontakt@hubertprzypis.pl
            </p>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => handleEmailCopy('hubert.przypis@proton.me')}
            >
              {t('contactSecureEmail')}: hubert.przypis@proton.me
            </p>
            {/* <p>{t('contactPhoneNumber')}: +48 XXX XXX XXX</p> */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Contact;