import React, { createContext, useContext, useState, useEffect } from 'react';

const translations = {
  en: {
    //home
    home: "Home",
    homeGreeting: "Hi There!",
    homeMyNameIs: "My name is",
    home2Introduce: "LET ME",
    home2Myself: "INTRODUCE MYSELF",
    home2Hello: "Hello! My name is Hubert and I'm on a",
    home2Journey: "journey, passionately exploring the IT world.",
    home2Passion: "Aspiring to become a skilled developer and CyberSecurity specialist. I am dedicated to continuous learning to get better every day. Besides my main interest, I am interested in cars and sports.",
    home2Join: "Join me",
    home2Adventure: "on this exciting adventure!",
    home2FindMe: "FIND ME ON",
    home2Connect: "Feel free to connect with me",
    flag: "/flags/usa.png",
    //about
    about: "About me",
    aboutMeTitle: "About me",
    aboutMeText: "Experienced IT professional with five years of expertise in installing, repairing, and maintaining computer systems, as well as programming. I prioritize continuous development—having completed the CISCO CCNA course and regularly participating in trainings and conferences.",
    passionsTitle: "Passions",
    passionsText: "Outside of my academic interests, I have a deep passion for new technologies like machine learning, artificial intelligence, and cybersecurity. These areas not only fascinate me but also drive my continuous journey of discovery and learning.",
    aboutTitle: "Hello everyone, my name is Hubert",
    aboutSubtitle: "I am a second-year student of",
    aboutDegree: "Computer Science",
    aboutDegreeInfo: "studies.",
    //resume
    resume: "Resume",
    skillsetTitle: "Skillset",
    softwareDevelopment: "Software development",
    cyberSecurity: "CyberSecurity",
    webDevelopment: "Web development",
    itSpecialist: "IT specialist",
    seo: "SEO",
    marketingAds: "Marketing/Ads",
    //projects
    projects: "Projects and Realizations",
    projectsTitle: "Realizations and my own projects",
    OnlineProjectsTitle: "Glad you want to test my stuff :)",
    findMoreText: "Find more on my GitHub.",
    githubButton: "GitHub",
    visitButton: "Visit",
    closeButton: "Close",
    ebmDescription: "Website made for dental clinic",
    pimpDescription: "CMS store with modeling parts",
    hubertDescription: "The best website I have ever seen",
    OnlineDescription1: "Simple password generator",
    OnlineDescription2: "Check the speed and accuracy of your writing",
    OnlineDescription3: " Fighting for Life and Death. Will you accept the challenge?",
    BackButton: "Back to projects",
    ProjectInButton: "Online programs",
    browserPlay: "open in a new tab",
    //contact
    contact: "Contact",
    contactTitle: "Contact me",
    contactDescription: "If you have questions or want to get in touch, click the icon below",
    contactInfoTitle: "Contact information (click to copy)",
    contactEmailMain: "main E-mail",
    contactEmail2: "E-mail 2",
    contactSecureEmail: "secure E-mail",
    downloadCV: "Download CV"
  },
  pl: {
    //home
    home: "Strona główna",
    homeGreeting: "Cześć!",
    homeMyNameIs: "Nazywam się",
    home2Introduce: "POZWÓL, ŻE",
    home2Myself: "SIĘ PRZEDSTAWIĘ :)",
    home2Hello: "Cześć! Nazywam się Hubert i jestem w trakcie",
    home2Journey: "podróży po świecie IT.",
    home2Passion: "Dążę do tego, aby stać się wykwalifikowanym deweloperem oraz specjalistą ds. Cyberbezpieczeństwa. Jestem oddany ciągłemu uczeniu się, aby codziennie stawać się lepszym. Poza moimi głównymi zainteresowaniami, interesuję się samochodami i sportem.",
    home2Join: "Dołącz do mnie",
    home2Adventure: "w tej ekscytującej przygodzie!",
    home2FindMe: "ZNAJDŹ MNIE NA",
    home2Connect: "Śmiało skontaktuj się ze mną",
    flag: "/flags/poland.png",
    //about
    about: "O mnie",
    aboutMeTitle: "O mnie",
    aboutMeText: "Jestem doświadczonym profesjonalistą IT z 5-letnim doświadczeniem w instalacji, naprawie, konserwacji systemów komputerowych oraz programowaniu. Skupiam się na ciągłym rozwoju, dlatego ukończyłem kurs CISCO CCNA i aktywnie rozwijam swoje kompetencje, uczestnicząc w szkoleniach i konferencjach.",
    passionsTitle: "Pasje",
    passionsText: "Poza moimi zainteresowaniami akademickimi, jestem głęboko pasjonatem nowych technologii, takich jak uczenie maszynowe, sztuczna inteligencja i Cyberbezpieczeństwo. Te obszary nie tylko mnie fascynują, ale także napędzają moją ciągłą podróż odkrywania i nauki.",
    aboutTitle: "Cześć wszystkim, nazywam się Hubert",
    aboutSubtitle: "Jestem studentem drugiego roku",
    aboutDegree: "informatyki",
    aboutDegreeInfo: "studiów.",
    //resume
    resume: "CV",
    skillsetTitle: "Umiejętności",
    softwareDevelopment: "Rozwój oprogramowania",
    cyberSecurity: "Cyberbezpieczeństwo",
    webDevelopment: "Tworzenie stron internetowych",
    itSpecialist: "Specjalista IT",
    seo: "SEO",
    marketingAds: "Marketing/Reklama",
    
    //projects
    projects: "Projekty i realizacje",
    projectsTitle: "Realizacje i moje projekty",
    OnlineProjectsTitle: "Cieszę się, że chcesz przetestować moje projekty :)",
    findMoreText: "Znajdź więcej na moim GitHubie.",
    githubButton: "GitHub",
    visitButton: "Odwiedź",
    closeButton: "Zamknij",
    ebmDescription: "Strona internetowa stworzona dla kliniki stomatologicznej",
    pimpDescription: "Sklep CMS z częściami do modelowania",
    hubertDescription: "Najlepsza strona, jaką kiedykolwiek widziałem",
    OnlineDescription1: "Prosty generator haseł",
    OnlineDescription2: "Sprawdź szybkość i dokładność swojego pisania",
    OnlineDescription3: "Walka na życie i śmierć. Czy podejmiesz wyzwanie?",
    BackButton: "Powrót do projektów",
    ProjectInButton: "Programy online",
    browserPlay: "otwórz w nowej karcie",
    
    //contact
    contact: "Kontakt",
    contactTitle: "Skontaktuj się ze mną",
    contactDescription: "Jeśli masz pytania lub chcesz się skontaktować, kliknij ikonę poniżej",
    contactInfoTitle: "Informacje kontaktowe (kliknij, aby skopiować)",
    contactEmailMain: "główny e-mail",
    contactEmail2: "e-mail 2",
    contactSecureEmail: "bezpieczny e-mail",
    downloadCV: "Pobierz CV",
  },
};

const LanguageContext = createContext();

export const useTranslation = () => {
  const { language, setLanguage, currentFlag } = useContext(LanguageContext);

  const t = (key) => translations[language][key] || key;

  return { t, setLanguage, currentFlag };
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Ustawienie domyślnego języka
  const [currentFlag, setCurrentFlag] = useState(translations['en'].flag);

  useEffect(() => {
    setCurrentFlag(translations[language].flag);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, currentFlag }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;